import { AddedProductModal, Breadcrumbs, Container, GRichText, LogoLoader } from "@/components";
import { Favorite } from "@/components/Icon/Favorite";
import { WishlistModal } from "@/containers";
import { useWishlist } from "@/lib/store/wishlist";
import { useStores } from "@/stores";
import { ContentLoader, Link, useI18n } from "@jog/react-components";
import { gtmWishlistView, lowerCaseLocale, setWishlistModalTime, shouldShowWishlistModal, } from "@jog/shared";
import { observer } from "mobx-react";
import { useSession } from "next-auth/react";
import { useEffect, useMemo, useState } from "react";
import WishlistItem from "./WishlistItem";
const EmptyWishlistContent = ({ setModalShow, content, }) => {
    const { $t } = useI18n();
    const session = useSession();
    const isLogin = session.status === "authenticated";
    return (<div className="my-15">
            <div className="flex flex-col items-center justify-center">
                <div className="mb-2">
                    <Favorite fill="black"/>
                </div>
                <GRichText field={content.explanation} paragraphClass="text-11 lg:text-sm leading-normal px-6 text-center" h2Class="text-2xl"/>
            </div>
            <div className="mt-6 flex gap-2 text-11 lg:mt-10 lg:justify-center lg:gap-10 lg:text-sm">
                <div className="flex-1 cursor-pointer border border-black bg-white py-2.5 text-center lg:flex-none lg:px-10">
                    <Link to={content.inspired}>{$t("get inspired")}</Link>
                </div>
                {!isLogin && (<div className="flex-1 cursor-pointer border border-black bg-black py-2.5 text-center text-white lg:flex-none lg:px-10" onClick={() => setModalShow(true)}>
                        {$t("login in wishlist")}
                    </div>)}
            </div>
        </div>);
};
const WishlistContent = ({ content }) => {
    const [loaded, setLoaded] = useState(false);
    const [skuForPopup, setSkuForPopup] = useState("");
    const { $t } = useI18n();
    const { cartStore } = useStores();
    const { addProductFetching, isAddedProductModalShown } = cartStore;
    const getWishlist = useWishlist.use.getWishlist();
    const wishlistPending = useWishlist.use.pending();
    const actionPending = useWishlist.use.actionPending();
    const wishlist = useWishlist.use.wishlist();
    const lineItems = useMemo(() => (wishlist === null || wishlist === void 0 ? void 0 : wishlist.lineItems) || [], [wishlist === null || wishlist === void 0 ? void 0 : wishlist.lineItems]);
    useEffect(() => {
        if (lineItems.length === 0)
            return;
        const gtmProducts = lineItems.map((item) => ({
            id: item.id,
            name: item.productName[process.env.NEXT_PUBLIC_LOCALE] || item.productName.en,
            price: item.discountedPrice || item.originalPrice,
        }));
        gtmWishlistView(gtmProducts);
    }, [lineItems]);
    const loading = useMemo(() => addProductFetching || wishlistPending || actionPending, [actionPending, addProductFetching, wishlistPending]);
    useEffect(() => {
        getWishlist().then(() => setLoaded(true));
    }, [getWishlist]);
    const session = useSession();
    const isLoggedIn = session.status === "authenticated";
    const [modalShow, setModalShow] = useState(false);
    useEffect(() => {
        if (session.status === "loading")
            return;
        if (!isLoggedIn && shouldShowWishlistModal()) {
            setModalShow(true);
        }
    }, [isLoggedIn, loading, session.status]);
    return (<Container className="px-3 lg:px-0">
            {loading && <LogoLoader />}
            {loading && !loaded ? (<div className="mt-6 grid grid-cols-2 gap-x-3 gap-y-3 pb-6 lg:grid-cols-5 lg:gap-x-5">
                    {Array(20)
                .fill(0)
                .map((_, index) => (<ContentLoader key={index} height="350px"/>))}
                </div>) : lineItems.length ? (<>
                    <div className="mt-6 block font-sans text-11 lg:hidden">
                        <Link to="/">{$t("Home")}</Link> <span>{` / ${$t("favorite")}`}</span>
                    </div>
                    <Breadcrumbs list={[
                { text: $t("favorite"), href: lowerCaseLocale() === "nl-nl" ? "/favorieten" : "/favorite" },
            ]}/>
                    <div className="my-4">
                        <h1 className="text-sm font-medium leading-none lg:text-base">{$t("favorite")}</h1>
                        <div className="text-11 lg:text-sm">
                            {$t("{count} favorite item", { count: `${lineItems.length}` })}
                        </div>
                    </div>

                    <div className="grid grid-cols-2 gap-x-3 gap-y-12 pb-6 lg:grid-cols-5 lg:gap-x-5">
                        {lineItems.map((item) => (<WishlistItem pdpData={item} key={item.id} setSkuForPopup={setSkuForPopup}/>))}
                    </div>
                </>) : (<EmptyWishlistContent content={content} setModalShow={setModalShow}/>)}
            {isAddedProductModalShown && !loading && <AddedProductModal productSku={skuForPopup}/>}
            {modalShow && (<WishlistModal content={content} hideModal={() => {
                setWishlistModalTime();
                setModalShow(false);
            }}/>)}
        </Container>);
};
export default observer(WishlistContent);

export const ImgTypes = {
    thumbnail: "thumbnail",
    promotionalTag: "promotional_tag",
    listerTile: "lister_tile",
    brandLogo: "brand_logo",
    pdpMainImage: "pdp_main_image",
    pageNotFoundImage: "page_not_found_image",
    paymentMethodIcon: "payment_method_icon",
    // navigationImage: "navigation_image",
    socialIcon: "social_icon",
    footerLogo: "footer_logo",
    cartLineItemImage: "cart_item_image",
    listerTileImage: "lister_tile_image",
    listerTileImageThumb: "lister_tile_thumb",
    ratingStarEmpty: "rating_star_empty",
    ratingStarFull: "rating_star_full",
    paymentMethodIconGiftcard: "payment_method_icon_giftcard",
};
